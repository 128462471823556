import React, { useState } from 'react';

import { MEDIA_SECTION } from 'constants/mediaSections';
import getMediaSectionName from 'util/getMediaSectionName';

import classes from './contentFilter.module.css';


export const ContentFilter = ({ filterName, mediaType, defaultValue, onChangeHandler }) => {
    const [currentFilter, setCurrentFilter] = useState(defaultValue);
    const mediaFilters = [MEDIA_SECTION.MY, MEDIA_SECTION.TEAM];

    return (
        <div className={classes.wrapper}>
            {
                mediaFilters.map(filter => (
                    <div className={classes.radio_btn} key={filter}>
                        <input
                            id={`${filterName}_${mediaType}_${filter}`}
                            type='radio'
                            name={`${filterName}`}
                            value={filter}
                            onChange={e => {
                                const value = e.target.value;
                                setCurrentFilter(value);
                                onChangeHandler(value);
                            }}
                            checked={currentFilter === filter}/>
                        <label htmlFor={`${filterName}_${mediaType}_${filter}`}>{getMediaSectionName(filter, mediaType)}</label>
                    </div>
                ))
            }
        </div>
    );
}
