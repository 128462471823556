import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './checkbox.css';

export default class CheckboxComponent extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    checked: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
    name: PropTypes.string,
    theme: PropTypes.string
  };

  static defaultProps = {
    onChange: () => {}
  };

  render() {
    const { onChange, checked, disabled, name, id, children, theme = 'green' } = this.props;

    const checkboxClass = classNames({
      'onollo-checkbox': true,
      [`onollo-checkbox--theme-${theme}`]: true,
      'onollo-checkbox--checked': checked,
      'onollo-checkbox--disabled': disabled
    });
    return (
      <div onClick={onChange} className={checkboxClass}>
        <input
          type="checkbox"
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          name={name ? name : ''}
          id={id ? id : ''}
        />
        {children}
      </div>
    );
  }
}
