import React, { memo, useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import Button from 'components/common/button/button';
import { MediaItem } from './components/MediaItem';
import ModalComponent from 'components/common/modal/modal';
import Loader from 'components/common/loader/loader';
import { ContentFilter } from 'components/blocks/contentFilter/contentFilter';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useActions } from 'hooks';
import { showModal, hideModal, modalTypes } from 'store/actions/modal/modal';
import { getMediaAction, clearMediaUploadsErrorAction } from 'store/actions/cloud';
import getModalData from 'util/getModalData';
import { CONTENT_PAGE_SIZE } from 'constants/limits';
import PurpleLogo from 'assets/images/logos/onollo-purple-circle.svg';
import CloseBoldIcon from 'assets/images/icons/close-bold.svg';
import classes from './cloudSelectModal.module.css';

import getMediaListName from 'util/getMediaListName';

import { MEDIA_SECTION } from 'constants/mediaSections';

const navItems = [{ label: 'Images', value: 'image' }, { label: 'Videos', value: 'video' }];

export const CloudSelectModal = memo(() => {
  const [currentSection, setCurrentSection] = useState(MEDIA_SECTION.MY);
  const mediaListName = getMediaListName(currentSection);
  const [hideMediaType, setHideMediaType] = useState();
  const [mediaType, setMediaType] = useState();
  const [viewModeForMediaId, setViewModeForMediaId] = useState();
  const [selectedMedias, setSelectedMedias] = useState([]);

  const actions = useActions({
    showModal,
    hideModal,
    getMediaAction,
    clearMediaUploadsErrorAction
  });
  const mediaUploads = useSelector(state => state.cloud[mediaListName][mediaType]) || { list: [] };
  const modalData = useSelector(state => getModalData(state, modalTypes.CLOUD_SELECT));
  const { data, isOpen } = modalData || {};
  const initMediaType = data?.initMediaType;
  const onAddMedia = data?.onAddMedia;
  const videosSelectLimit = data?.videosSelectLimit || Infinity;

  const filteredNavItems = useMemo(() => {
    return hideMediaType === 'all'
      ? navItems
      : navItems.filter(item => item.value !== hideMediaType);
  }, [hideMediaType]);

  // Hooks
  useEffect(() => {
    if (!isOpen) {
      setHideMediaType(undefined);
      setMediaType(undefined);
      setViewModeForMediaId(undefined);
      setSelectedMedias([]);
    }
  }, [isOpen]);

  useEffect(() => {
    if (initMediaType) {
      if (initMediaType !== 'all') {
        setMediaType(initMediaType);
        setHideMediaType(initMediaType === 'image' ? 'video' : 'image');
      } else {
        setMediaType(navItems[0].value);
      }
    }
  }, [initMediaType]);

  useEffect(() => {
    if (
      isOpen &&
      mediaType &&
      !mediaUploads.didFirstLoad &&
      mediaUploads.list.length < CONTENT_PAGE_SIZE
    ) {
      // TODO
      actions.getMediaAction(mediaType, currentSection, 0);
    }
  }, [isOpen, mediaType, mediaUploads.didFirstLoad]);

  // Handlers
  const handleCloseModal = useCallback(() => {
    actions.hideModal(modalTypes.CLOUD_SELECT, {}, true);
  }, []);

  const onSetViewMode = useCallback(fileId => {
    setViewModeForMediaId(fileId);
  }, []);

  const onToggleMedia = useCallback(
    (isSelected, file) => {
      let nextSelectedMedias = isSelected
        ? [...selectedMedias, file]
        : selectedMedias.filter(item => item.id !== file.id);

      if (nextSelectedMedias.length > videosSelectLimit && file.type === 'VIDEO') {
        return;
      }

      // Filter selected medias to current media type
      if (nextSelectedMedias.length && hideMediaType !== 'all') {
        nextSelectedMedias = nextSelectedMedias.filter(item => item.type === file.type);
      }

      // Set hide media type
      if (nextSelectedMedias.length) {
        setHideMediaType(file.type === 'IMAGE' ? 'video' : 'image');
      } else {
        initMediaType === 'all'
          ? setHideMediaType('all')
          : setHideMediaType(initMediaType === 'image' ? 'video' : 'image');
      }

      setSelectedMedias(nextSelectedMedias);
    },
    [selectedMedias, initMediaType, hideMediaType]
  );

  const handleAddMedia = useCallback(() => {
    handleCloseModal();
    setSelectedMedias([]);

    onAddMedia && onAddMedia(mediaType, selectedMedias);
  }, [onAddMedia, mediaType, selectedMedias]);

  const onLoadMoreFiles = useCallback(() => {
    actions.getMediaAction(mediaType, currentSection, mediaUploads.list.length);
  }, [mediaUploads.list.length]);

  function onChangeFilter(filter) {
    setCurrentSection(filter);
  }

  return (
    <ModalComponent
      center
      isOpen={!!isOpen}
      closeModal={handleCloseModal}
      hideCloseButton={true}
      modalPadding="0"
      width={810}
      className={classes['cloud']}
    >
      <>
        <button className={classes['cloud__close-btn']} onClick={handleCloseModal}>
          <img src={CloseBoldIcon} alt="icon" />
        </button>

        <div className={classes['cloud__flex']}>
          <div className={classes['cloud__left']}>
            <div className={classes['cloud__logo']}>
              <img src={PurpleLogo} alt="Logo" className={classes['cloud__logo-img']} />
            </div>
            <div className={classes['cloud__nav']}>
              {filteredNavItems.map(item => (
                <button
                  key={item.label}
                  onClick={() => setMediaType(item.value)}
                  disabled={mediaType === item.value || mediaUploads.isLoading}
                  className={cn(classes['cloud__nav-item'], {
                    [classes['cloud__nav-item_active']]: item.value === mediaType
                  })}
                >
                  {item.label}
                </button>
              ))}
            </div>
          </div>

          <div className={classes['cloud__right']}>
            <div className={classes['cloud__title-wrp']}>
              <h2 className={classes['cloud__title']}>Cloud</h2>
              <span className={classes['cloud__count']}>{mediaUploads.count} items</span>
            </div>

            <div className={classes['cloud__list-wrp']} id={classes['cloud__list-wrp']}>
              {mediaUploads.isLoading && !mediaUploads.didFirstLoad ? (
                <Loader img={{ width: 75, height: 75 }} className={classes['cloud__list-loader']} />
              ) : (
                <>
                  <ContentFilter
                    filterName='cloudSelectModalFilter'
                    mediaType={mediaType}
                    defaultValue={currentSection}
                    onChangeHandler={onChangeFilter}/>
                  <InfiniteScroll
                    scrollableTarget={classes['cloud__list-wrp']}
                    style={{ overflow: 'hidden' }}
                    dataLength={mediaUploads.list.length}
                    hasMore={mediaUploads.list.length < mediaUploads.count}
                    loader={<Loader img={{ width: 75, height: 75 }} />}
                    next={onLoadMoreFiles}
                  >
                    <div className={classes['cloud__list']}>
                      {mediaUploads.list.map(file => (
                        <MediaItem
                          key={file.id}
                          file={file}
                          viewMode={viewModeForMediaId === file.id}
                          selected={!!selectedMedias.find(media => media.id === file.id)}
                          onSetViewMode={onSetViewMode}
                          onToggleSelect={onToggleMedia}
                        />
                      ))}
                    </div>
                  </InfiniteScroll>
                </>
              )}
            </div>

            <div className={classes['cloud__actions']}>
              <div className={classes['cloud__selected-info']}>
                <span>
                  {selectedMedias.length}
                  {mediaType === 'video' && `/${videosSelectLimit}`}
                </span>{' '}
                <strong>Items selected</strong>
              </div>
              <Button
                black
                label="Add"
                className={classes['cloud__action-add']}
                disabled={!selectedMedias.length}
                onClick={handleAddMedia}
              />
            </div>
          </div>
        </div>
      </>
    </ModalComponent>
  );
});
