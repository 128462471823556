import React, { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useActions } from 'hooks';
import { spyFeedSetCompetitorAction, spyFeedGetAction } from 'store/actions/spyFeed/spyFeed';
import WithErrorHandling from 'components/common/withErrorHandling/withErrorHandling';
import SelectComponent from 'components/common/selectAsync/selectAsync';
import classes from '../../spyFeed.module.css';

export const DEFAULT_COMPETITOR = { value: null, label: 'All competitors' };

export const FilterByCompetitor = memo(() => {
  const actions = useActions({ spyFeedSetCompetitorAction, spyFeedGetAction });
  const competitor = useSelector(state => state.spyFeed.competitor);
  const socialType = useSelector(state => state.spyFeed.socialType);
  const [error, setError] = useState('');

  async function loadOptions(_, prevOptionsData) {
    let prevOptions = prevOptionsData.filter(opt => opt.value !== DEFAULT_COMPETITOR.value);

    try {
      const res = await axios.get(`/api/spy/pages?type=${socialType}`);
      return {
        options: [
          DEFAULT_COMPETITOR,
          ...prevOptions,
          ...res.data
            .filter(account => account.status === 'ACTIVE')
            .map(account => ({ value: account.id, label: account.name }))
        ],
        hasMore: false
      };
    } catch (err) {
      setError(err.response.data?.message || 'Something went wrong on load competitors');
      return {
        options: prevOptions,
        hasMore: false
      };
    }
  }

  function handleChooseCompetitor(option) {
    actions.spyFeedSetCompetitorAction(option);
    actions.spyFeedGetAction();
  }

  return (
    <WithErrorHandling
      showError={!!error}
      errorMessage={error}
      clearErrorHandler={() => setError('')}
    >
      <div className={classes['spy-feed__filters-select-wrp']}>
        <SelectComponent
          cacheUniqs={[socialType]}
          isSearchable={false}
          value={competitor}
          onChange={handleChooseCompetitor}
          classNamePrefix="fill-grey-select"
          loadOptions={loadOptions}
        />
      </div>
    </WithErrorHandling>
  );
});
