import { getStoredState, REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export default function crossBrowserListener(e, store) {
  switch (e.key) {
    case 'username':
    case 'id_token':
      if (!e.newValue && e.oldValue) {
        window.location.replace('/login');
      }
      if (e.newValue && !e.oldValue) {
        window.location.replace('/app');
      }
      break;
    case 'persist:accountsList':
    case 'persist:smartSchedule':
    case 'persist:calendar':
    case 'persist:templateUser':
    case 'persist:autopilot':
    case 'persist:videoList':
      (async function() {
        const key = e.key.split(':')[1];
        let state = await getStoredState({ key, storage });

        store.dispatch({
          type: REHYDRATE,
          key,
          payload: state
        });
      }());
      break;
    default:
      break;
  }
}
