export const SET_ACTIVE_SOURCE_TAB = 'SET_ACTIVE_SOURCE_TAB';
export const CHANGE_IS_SHORT_LINK = 'CHANGE_IS_SHORT_LINK';

export function setActiveSource(sourceName) {
  return {
    type: SET_ACTIVE_SOURCE_TAB,
    sourceName,
  };
}

export function setIsShortLink(isShortLink) {
  return {
    type: CHANGE_IS_SHORT_LINK,
    isShortLink,
  };
}
