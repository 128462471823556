export const SOCIAL_TYPE_AVAILABLE = ['FACEBOOK','TWITTER','LINKEDIN', 'INSTAGRAM', 'PINTEREST'];

export const SOCIAL_TYPES_AVAILABLE = [
  {
    id: 0,
    name: 'FACEBOOK',
    title: 'Facebook'
  },
  {
    id: 1,
    name: 'INSTAGRAM',
    title: 'Instagram'
  },
  {
    id: 2,
    name: 'PINTEREST',
    title: 'Pinterest'
  },
  {
    id: 3,
    name: 'TWITTER',
    title: 'Twitter'
  },
  {
    id: 4,
    name: 'LINKEDIN',
    title: 'LinkedIn'
  }
];
