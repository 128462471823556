import { createSelector } from 'reselect';

//Constants
import { PREFIX_COLLECTION_SELECT } from 'constants/collectionSelectPrefix';

//Utils
import { returnObjForFilterOptionProduct } from 'util/returnObjForOptionProduct';
import { getActiveSourceTab } from './sourcesSelelector';

const getModalSourceType = state => state.postModal.sourceType;
const getModalSourceBrand = state => state.postModal.sourceBrand;
const getStoresPayload = state => state.storesList.payload;

const getProducts = state => state.productList.payload;

export const getBrandsFromState = state => state.productList.brands;

export const getCollectionNames = state => state.productList.filterOptions;

export const getMediaTags = state => state.productList.mediaTags;

export const getBrandsFromProducts = createSelector(
  [getProducts],
  products => {
    return [...new Set(products.map(product => product.brand))].filter(brand => !!brand);
  }
);

export const getBrandsSort = createSelector(
  [getBrandsFromState],
  brands => {
    return [...brands].sort((a, b) => a.localeCompare(b, undefined, { numeric: true }));
  }
);

export const getOptionsSelect = createSelector(
  [getCollectionNames, getBrandsFromState],
  (collectionNames, brandNames) => {
    //Types add later
    const collectionOption = collectionNames
      ? collectionNames.map(collection => ({
          value: `${PREFIX_COLLECTION_SELECT.collections}${collection.id}`,
          label: `${collection.name}(${collection.productCounter})`
        }))
      : [];

    const brandOption = brandNames
      ? [...brandNames].sort().map(brand => {
          const brandUppercase = brand.name ? brand.name.toUpperCase() : brand.toUpperCase();
          return {
            value: `${PREFIX_COLLECTION_SELECT.brands}${brandUppercase}`,
            label: brandUppercase
          };
        })
      : [];

    return [
      {
        label: 'Collections',
        options: collectionOption
      },
      {
        label: 'Brands',
        options: brandOption
      }
    ];
  }
);

const _helperCheckedProductOptions = checkOptions => {
  if (Array.isArray(checkOptions)) {
    return checkOptions.map(returnObjForFilterOptionProduct);
  } else return [];
};

export const getCheckedCategoryAutopilotArray = state => state.autopilot.form.collections;

export const getCheckedCategoryAutopilot = createSelector(
  [getCheckedCategoryAutopilotArray],
  _helperCheckedProductOptions
);

export const getCheckedCategoryTemplateArray = state => state.templateUser.form.collections;

export const getCheckedCategoryTemplate = createSelector(
  [getCheckedCategoryTemplateArray],
  _helperCheckedProductOptions
);

export const getCheckedBrandAutopilotArray = state => state.autopilot.form.brands;

export const getCheckedBrandAutopilot = createSelector(
  [getCheckedBrandAutopilotArray],
  _helperCheckedProductOptions
);

export const getCheckedStockLevelsAutopilotArray = state => state.autopilot.form.stockLevels;

export const getCheckedStockLevelsAutopilot = createSelector(
  [getCheckedStockLevelsAutopilotArray],
  _helperCheckedProductOptions
);

export const getCheckedProductFeaturesAutopilotArray = state =>
  state.autopilot.form.productFeatures;

export const getCheckedProductFeaturesAutopilot = createSelector(
  [getCheckedProductFeaturesAutopilotArray],
  _helperCheckedProductOptions
);

export const getCheckedBrandTemplateArray = state => state.templateUser.form.brands;

export const getCheckedBrandTemplate = createSelector(
  [getCheckedBrandTemplateArray],
  _helperCheckedProductOptions
);

export const getCheckedStockLevelsTemplateArray = state => state.templateUser.form.stockLevels;

export const getCheckedStockLevelsTemplate = createSelector(
  [getCheckedStockLevelsTemplateArray],
  _helperCheckedProductOptions
);

export const getCheckedProductFeaturesTemplateArray = state =>
  state.templateUser.form.productFeatures;

export const getCheckedProductFeaturesTemplate = createSelector(
  [getCheckedProductFeaturesTemplateArray],
  _helperCheckedProductOptions
);

export const getFilterOptionsByType = createSelector(
  [getCollectionNames, getActiveSourceTab],
  (categories, sourceTab) => {
    if (sourceTab && sourceTab.generalType === 'PRODUCT') {
      return categories.filter(category => category.shopType === sourceTab.name);
    } else {
      return [];
    }
  }
);

export const getCheckedCategories = createSelector(
  [getFilterOptionsByType],
  categories => {
    return categories
      .filter(category => category.selectedInPublications)
      .map(returnObjForFilterOptionProduct);
  }
);

export const getPayloadActiveShop = createSelector(
  [getProducts, getActiveSourceTab],
  (productList, sourceTab) => {
    if (sourceTab?.generalType === 'PRODUCT') {

      return productList.filter(
        product => product?.shop?.type === sourceTab.name
      );
    } else {
      return [];
    }
  }
);

export const getStoresListByType = createSelector(
  [getStoresPayload, getActiveSourceTab],
  (storesList, sourceTab) => {
    if (sourceTab?.generalType === 'PRODUCT') {
      return storesList.filter(store => store.type === sourceTab.name);
    } else {
      return storesList;
    }
  }
);

export const getFirstStoreByType = createSelector(
  [getStoresPayload, getActiveSourceTab],
  (storesList, sourceTab) => {
    if (sourceTab?.generalType === 'PRODUCT') {
      return storesList.filter(store => store.type === sourceTab.name)[0] || null;
    } else {
      return null;
    }
  }
);

export const getMoadlStoreByType = createSelector(
  [getStoresPayload, getModalSourceType, getModalSourceBrand],
  (storesList, sourceType, sourceBrand) => {
    if (sourceType === 'PRODUCT') {
      return storesList.filter(store => store.type === sourceBrand)[0] || null;
    } else {
      return null;
    }
  }
);

export const getTotalCountByStoreType = createSelector(
  [getStoresListByType],
  storeList => {
    return storeList.reduce((count, store) => {
      count += store.totalCountOfProducts;
      return count;
    }, 0);
  }
);

const getAutopilotSelectedStoresIds = state => state.autopilot.form.selectedStoresIds;

export const getCheckedStoresSelector = createSelector(
  [getAutopilotSelectedStoresIds, getStoresPayload],
  (autopilotSelectedStoresIds, storeListPaylod) => {
    return autopilotSelectedStoresIds.map(storeId => {
      const store = storeListPaylod.find(store => store.id === storeId);

      if (!store) {
        return null;
      }


      return {
        id: store.id,
        value: store.id,
        label: store.name
      };
    });
  }
);
