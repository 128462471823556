import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { hideModal } from 'store/actions/modal/modal';
import Button from 'components/common/button/button';
import { useActions } from 'hooks';
import classes from './upgradePlanPopover.module.css';

export const UpgradePlanPopover = ({ onClose, className, withCancelBtn = true }) => {
  const actions = useActions({ hideModal });

  return (
    <div className={cn(className, classes['upgrade-plan'])}>
      <h2 className={classes['upgrade-plan__title']}>Upgrade to Pro plan.</h2>
      <p className={classes['upgrade-plan__desc']}>
        Publish more posts, create unlimited autopilots, add your teammates
        and more with Onollo Pro.
      </p>
      <div className={classes['upgrade-plan__actions']}>
        <Link
          to="/upgrade"
          onClick={() => {
            actions.hideModal();
            onClose && onClose();
          }}
        >
          <Button yellow label="Select a plan" />
        </Link>
        {withCancelBtn && (
          <Button
            transparentWithoutBorder
            label="No, thanks"
            onClick={() => onClose && onClose()}
          />
        )}
      </div>
    </div>
  );
};
