import React from 'react';
import fbLogo from 'assets/images/icons/fb.svg';
import instaLogo from 'assets/images/icons/instagram.svg';
import twLogo from 'assets/images/icons/tw.svg';
import inLogo from 'assets/images/icons/linkedin.svg';
import pinLogo from 'assets/images/icons/pinterest.svg';
import shopify from 'assets/images/icons/shopify.svg';
import shopifyCircle from 'assets/images/icons/shopify-circle.svg';
import magento from 'assets/images/icons/magento.svg';
import woocommerce from 'assets/images/icons/woo.svg';
import wix from 'assets/images/icons/wix-logo.svg';
import wish from 'assets/images/icons/wish-logo.svg';
import bigcommerce from 'assets/images/icons/bigcommerce.svg';
import youtube from 'assets/images/icons/youtube_rect.svg';
import bitly from 'assets/images/icons/bitly.svg';
import mediaImage from 'assets/images/icons/sources/cloud-image.svg';
import mediaVideo from 'assets/images/icons/sources/cloud-video.svg';
import none from 'assets/images/icons/none.svg';
import { MAGENTO, SHOPIFY, WOOCOMMERCE, BIGCOMMERCE, WIX, WISH } from '../constants/stores';

export const getSocialIcon = socialType => {
  switch (socialType) {
    case 'FACEBOOK':
      return <img src={fbLogo} alt="FACEBOOK" width="32px" height="32px" />;

    case 'INSTAGRAM':
      return <img src={instaLogo} alt="INSTAGRAM" width="32px" height="32px" />;

    case 'TWITTER':
      return <img src={twLogo} alt="TWITTER" width="32px" height="32px" />;

    case 'LINKEDIN':
      return <img src={inLogo} alt="LINKEDIN" width="32px" height="32px" />;

    case 'PINTEREST':
      return <img src={pinLogo} alt="PINTEREST" width="32px" height="32px" />;

    case SHOPIFY:
    case 'PRODUCT':
      return <img src={shopify} alt="SHOPIFY" width="32px" height="32px" />;

    case 'SHOPIFY:CIRCLE':
      return <img src={shopifyCircle} alt="SHOPIFY" width="32px" height="32px" />;

    case MAGENTO:
      return <img src={magento} alt="MAGENTO" width="32px" height="32px" />;

    case WOOCOMMERCE:
      return <img src={woocommerce} alt="WOOCOMMERCE" width="32px" height="32px" />;

    case BIGCOMMERCE:
      return <img src={bigcommerce} alt="BIGCOMMERCE" width="32px" height="32px" />;

    case WIX:
      return <img src={wix} alt="WIX" width="32px" height="32px" />;

    case WISH:
      return <img src={wish} alt="WISH" width="32px" height="32px" />;

    case 'GOOGLE':
    case 'YOUTUBE':
    case 'VIDEO':
      return <img src={youtube} alt="YOUTUBE" width="32px" height="32px" />;

    case 'BITLY':
      return <img src={bitly} alt="BITLY" width="32px" height="32px" />;

    case 'MEDIA_IMAGES':
      return <img src={mediaImage} title='Images' alt='' width="32px" height="32px" />;
    case 'MEDIA_VIDEOS':
      return <img src={mediaVideo} title='Videos' alt='' width="32px" height="32px" />;

    case 'NONE':
    case '':
      return <img src={none} alt="NONE" width="32px" height="32px" />;

    default:
      return null;
  }
};

export const getSocialIconFromLink = link => {
  const icon = getSocialIcon(
    /www\./.test(link)
      ? link.split('.')[1]
        ? link.split('.')[1].toUpperCase()
        : ''
      : link.split('.')[0].split('//')[1]
      ? link
          .split('.')[0]
          .split('//')[1]
          .toUpperCase()
      : ''
  );

  return icon ? icon : getSocialIcon('');
};

export const getSocialImage = socialType => {
  switch (socialType) {
    case 'FACEBOOK':
      return fbLogo;

    case 'INSTAGRAM':
      return instaLogo;

    case 'TWITTER':
      return twLogo;

    case 'LINKEDIN':
      return inLogo;

    case 'PINTEREST':
      return pinLogo;

    case SHOPIFY:
    case 'PRODUCT':
      return shopify;

    case MAGENTO:
      return magento;

    case WOOCOMMERCE:
      return woocommerce;

    case BIGCOMMERCE:
      return bigcommerce;

    case WIX:
      return wix;

    case WISH:
      return wish;

    case 'YOUTUBE':
    case 'GOOGLE':
    case 'VIDEO':
      return youtube;

    case 'BITLY':
      return bitly;

    case 'MEDIA_IMAGES':
      return mediaImage;
    case 'MEDIA_VIDEOS':
      return mediaVideo;

    case 'NONE':
      return none;

    default:
      return null;
  }
};

export default getSocialIcon;
