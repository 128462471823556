import moment from 'moment';
import { TIME_FORMAT } from 'constants/date';

/**
   * Convert utc day of the week and utc time to local.
   *
   * @return {object} Example: {dayOfWeek: 'MONDAY', time: '12:00'}.
   */
export default function utcTimeTolocalTime(utcDayOfWeek, utcTime) {
  const closestDayOfWeek = moment()
    .startOf('isoWeek')
    .add(1, 'week')
    .day(utcDayOfWeek);

  const localTime = moment.utc(closestDayOfWeek.format('YYYY-MM-DD') + ' ' + utcTime).local();

  return {
    dayOfWeek: localTime.format('dddd').toUpperCase(),
    time: localTime.format(TIME_FORMAT)
  }
}
