import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import axios from 'axios';
import isEqual from 'lodash/isEqual';
import { Provider } from 'react-redux';
import AuthService from 'util/authService';
import SockJS from 'sockjs-client';
import { Stomp } from '@stomp/stompjs';
import { getWebsocketUrl } from 'constants/webSocket';
import { store, persistor } from 'store';
import { PersistGate } from 'redux-persist/integration/react';
import { QueryClient, QueryClientProvider, setLogger } from 'react-query';
import { setCurrentUser, logoutAction } from 'store/actions/auth/auth';
import { profileGetAction } from 'store/actions/profile/profile';
import { getPublishedPostsCounterAction } from 'store/actions/counters';
import RouterConfig from './routerConfig';
import * as serviceWorker from 'util/serviceWorker';
import ScrollToTop from 'components/blocks/ScrollToTop';
import crossBrowserListener from 'util/reduxpersist-listener';
import translateFixError from './util/temporaryFix/translateFixError';
import 'assets/styles/index.css';

const Auth = new AuthService();
export const clientWS = Stomp.over(() => new SockJS(getWebsocketUrl()));
if (Auth.loggedIn()) {
  clientWS.reconnect_delay = 100;
  clientWS.debug = () => {};
  clientWS.connect({}, () => {});
}

axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 120000
});

axios.defaults.timeout = 120000;

axios.interceptors.request.use(config => {
  const refreshTokenUrl = '/api/refresh-token';
  if (config.url === refreshTokenUrl) {
    return config;
  }

  if (Auth.getToken() && Auth.isTokenExpired()) {
    return axios.post(refreshTokenUrl).then((res) => {
      if (res?.data['id_token']) {
        const newToken = res.data['id_token'];
        const oldProfile = Auth.getProfile();
        const newProfile = Auth.getProfileFromToken(newToken);

        Auth.setToken(newToken);
        Auth.setAuthHeaders();
        import('socketConnects/init').then(data => data.initWSConnects());

        config.headers.Authorization = 'Bearer ' + newToken;
        if (!isEqual(oldProfile.auth.sort(), Auth.getProfileFromToken(newToken).auth.sort())
        || oldProfile.company !== newProfile.company) {
          window.location.replace('/app');
        }
        return Promise.resolve(config);
      } else {
        store.dispatch(logoutAction());
        window.location.replace('/home');
        return Promise.resolve(config);
      }
    }).catch(error => {
      if (error?.response?.status < 500) {
        store.dispatch(logoutAction());
        window.location.replace('/home');
      }
      return Promise.reject(error);
    });
  }
  return config;
  },
  error => {
      return Promise.reject(error)
  });

  axios.interceptors.response.use(response => {
      return response;
    }, error => {
      Promise.reject(error).catch(res => {
        if (res?.response?.data?.status === 401 && res?.response?.data?.outdatedToken) {
          store.dispatch(logoutAction());
          window.location.replace('/home');
        }
      });
      return Promise.reject(error);
    });

if (Auth.loggedIn()) {
  Auth.setAuthHeaders();
  store.dispatch(setCurrentUser());
  store.dispatch(profileGetAction());
  store.dispatch(getPublishedPostsCounterAction());
  import('socketConnects/init').then(data => data.initWSConnects());
} else {
  store.dispatch(logoutAction());
}

window.addEventListener('storage', e => {
  crossBrowserListener(e, store);
});

translateFixError();

// react-query
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
      cacheTime: 0
    }
  }
});
setLogger({ error: () => {}, warn: console.warn, log: console.log });

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <QueryClientProvider client={queryClient}>
        <Router>
          <ScrollToTop>
            {location => (
              <RouterConfig
                loggedIn={Auth.loggedIn()}
                auth={store.getState().auth}
                location={location}
              />
            )}
          </ScrollToTop>
        </Router>
      </QueryClientProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
serviceWorker.unregister();
