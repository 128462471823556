/**
 * remove Excess blank lines from html template
 * @example
 * // returns `<p>asd</p>
 * // <p><br></p>
 * // <p>saasd</p>
 * // <p>asddsa</p>'`
 * removeExcessBlankLines(`<p>sfsfd</p>
 * <p>one</p>
 * <p><br></p>
 * <p><br></p>
 * <p><br></p>
 * <p>two</p>
 * <p>three</p>`);
 * @returns {String} html template without double blank lines
 */
export const removeExcessBlankLines = (htmlTemplate) => {
  return htmlTemplate ? htmlTemplate.replace(/(<p>(\n|&nbsp;)*<br>(\n|&nbsp;)*<\/p>(\n|&nbsp;)*){1,}/gim, '<br>') : '';
};


/**
 * remove Excess break lines from post template
 * @example
 * // returns `one\n\n\ntwo'`
 * removeExcessBreakLines(`one\n\ntwo`);
 * @returns {String} post template without double break lines
 */
export const removeExcessBreakLines = (template) => {
  return template ? template.replace(/(\n){2,}/gim, '\n\n') : '';
};
