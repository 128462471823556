import React, { Component } from 'react';
import { connect } from 'react-redux';
import { hideModal, modalTypes } from 'store/actions/modal/modal';
import getModalData from 'util/getModalData';
import ModalComponent from 'components/common/modal/modal';
import Button from 'components/common/button/button';

const mapStateToProps = state => ({
  modal: getModalData(state, modalTypes.INFO),
});

const mapDispatchToProps = {
  hideSelf: hideModal
};

class InfoModal extends Component {
  hideSelfHandler = () => {
    const { data } = this.props.modal;
    this.props.hideSelf(modalTypes.INFO);
    if (data && data.closePostHandler) data.closePostHandler()
  };

  render() {
    const { data, isOpen } = this.props.modal;

    return (
      <ModalComponent
        closeModal={this.hideSelfHandler}
        isOpen={isOpen}
        data={data}
        center
        width={640}>
        <div className="modal__content onollo__modal">
          <h2 className="h2">{data && data.title ? data.title : null}</h2>
          <div className="modal__info">{data && data.text ? data.text : null}</div>
          <div className="modal__manipulation modal__manipulation--single-btn">
            <Button
              success
              label={data && data.buttonLabel ? data.buttonLabel : 'Close'}
              onClick={this.hideSelfHandler}
            />
          </div>
        </div>
      </ModalComponent>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InfoModal);
