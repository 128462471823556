export default word => {
  if (!word) return word;
  word = word.toLowerCase();

  if (word === 'videos') {
    return 'YouTube';
  }
  if (word === 'linkedin') {
    return 'LinkedIn';
  }
  if (word === 'media_images') {
    return 'Images';
  }
  if (word === 'media_videos') {
    return 'Videos';
  }

  if (word === 'bigcommerce') {
    return 'BigCommerce';
  }
  if (word === 'woocommerce') {
    return 'WooCommerce';
  }

  return `${word[0].toUpperCase() + word.slice(1)}`.replace(/_/g, ' ');
};
