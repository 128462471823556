import sprite from 'assets/images/sprite.svg';

const selectStyle = {
  container: (base, state) => ({
    ...base,
    opacity: state.isDisabled ? '0.45' : 1
  }),
  control: (base, state) => {
    const bc = _getBc(state.isFocused, state.selectProps.classNamePrefix);
    return ({
    ...base,
    borderColor: bc,
    minHeight: state.selectProps.classNamePrefix === 'base-select-template' ? '36px': '55px',
    backgroundColor: '#fff',
    boxShadow: 'none',
    cursor: 'pointer',
    overflow: 'hidden'
  })},
  valueContainer: (base, state) => ({
    ...base,
    maxHeight: '55px',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: '-17px',
    overflowY: 'hidden',
    padding: '2.5px 0 2.5px 2.5px',
    width: 'calc(100% - 50px)'
  }),
  placeholder: (base, state) => {
    const color = _getPlaceholderColor(state.isFocused, state.selectProps.classNamePrefix);
    return ({
    ...base,
    fontSize: '16px',
    color: color,
    paddingLeft: '21px',
    margin: 0
  })},
  input: (base, state) => ({
    ...base,
    opacity: 1,
    marginLeft: 18
  }),
  multiValue: (base, state) => ({
    ...base,
    height: '45px',
    margin: '2.5px 0 2.5px 2.5px',
    padding: '0 37px 0 15px',
    borderRadius: '4px',
    backgroundColor: '#F5F8F8',
    position: 'relative'
  }),
  multiValueLabel: (base, state) => ({
    ...base,
    color: '#2B3847',
    fontSize: '16px',
    lineHeight: '45px'
  }),
  multiValueRemove: (base, state) => ({
    ...base,
    fontSize: 0,
    color: state.selectProps.classNamePrefix === 'base-select-dark' ? 'var(--color-dark) !important' : '#2AAD5B !important',
    backgroundColor: '#F5F8F8 !important',
    backgroundImage: state.selectProps.classNamePrefix === 'base-select-dark' ? 'unset' : `url(${sprite})`,
    backgroundPosition: '-199px -99px',
    backgroundRepeat: 'no-repeat',
    width: '11px',
    height: '12px',
    margin: 0,
    padding: 0,
    position: 'absolute',
    right: '15px',
    top: '17px',
    cursor: 'pointer'
  }),
  singleValue: (base, state) => ({
    ...base,
    fontSize: '16px',
    color: state.selectProps.classNamePrefix === 'base-select-template' ? 'rgba(0,0,0,0)' :'#2B3847',
    marginLeft: '2px',
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    fontSize: 0,
    color: '#2AAD5B !important',
    backgroundImage: `url(${sprite})`,
    backgroundPosition: state.isFocused ? '-302px -91px' : '-267px -91px',
    backgroundRepeat: 'no-repeat',
    width: '8px',
    height: '6px',
    padding: '14px 16px',
    cursor: 'pointer',
    position: 'absolute',
    right: 0,
    top: state.selectProps.classNamePrefix === 'base-select-template' ? '2px' : '14px'
  }),
  groupHeading: (base, state) => ({
    ...base,
    padding: 0,
    margin: 0
  }),
  indicatorSeparator: (base, state) => ({
    ...base,
    display: 'none !important',
    fontSize: 0,
    opacity: 0
  }),
  clearIndicator: (base, state) => ({
    ...base,
    display: 'none !important',
    fontSize: 0,
    opacity: 0
  }),
  menu: (base, state) => ({
    ...base,
    boxShadow: '0 6px 12px 0 rgba(0, 0, 0, 0.05)',
    border: 0,
    padding: state.selectProps.classNamePrefix === 'base-select-template' ? '8px 0px' : '8px 10px',
    zIndex: 6
  }),
  option: (base, state) => {
    const bgc = _getBgc(state.isSelected, state.isFocused, state.selectProps.classNamePrefix);
    const color = _getColor(state.isSelected, state.isFocused, state.selectProps.classNamePrefix);
    const height = _getHeight(state.selectProps.classNamePrefix);
    const padding = _getPadding(state.selectProps.classNamePrefix);
    return  ({
    ...base,
    height: height,
    borderRadius: 4,
    padding: padding,
    margin: state.selectProps.classNamePrefix === 'base-select-template' ? '8px' :'2px 0',
    color: color,
    width: state.selectProps.classNamePrefix === 'base-select-template' ? 'auto' :'100%',
    backgroundColor: bgc,
    cursor: 'pointer'
  })},
  loadingIndicator: (base, state) => ({
    ...base,
    display: 'block !important',
    position: 'absolute',
    top: '50%',
    right: 45,
    transform: 'translateY(-50%)'
  })
};

export default selectStyle;

export const baseStyle = {
  wrapper: {
    width: '100%',
    margin: '0 auto',
    fontFamily: `'Work Sans', sans-serif`
  }
};

const _getBgc = (isSelected, isFocused, classNamePrefix) => {
  if (!(isFocused || isSelected)) {
    return '#FFF';
  }
  switch(classNamePrefix){
    case 'base-select':
    case 'async-select':
      return '#2AAD5B !important';
    case 'base-select-dark':
      return isFocused ? 'var(--color-light-dark) !important' : 'var(--color-dark) !important';
    case 'base-select-template':
      return isSelected ? '#f0f0f0 !important' : 'var(--color-border) !important';
    default:
      return '#FFF';
  }
};

const _getColor = (isSelected, isFocused, classNamePrefix) => {
  if ((isFocused && classNamePrefix !== 'base-select-template') ||
    (isSelected && classNamePrefix === 'base-select-dark')){
    return '#FFF';
  }
  else {
    return '#384144';
  }
}

const _getBc = (isFocused, classNamePrefix) => {
  if (classNamePrefix === 'base-select-template') {
    return 'rgba(0,0,0,0) !important';
  }
  else if (isFocused) {
    return 'var(--color-border--active) !important';
  } else {
    return 'var(--color-border) !important';
  }
};

const _getPlaceholderColor = (isFocused, classNamePrefix) => {
  if (classNamePrefix === 'base-select-template') {
    return 'rgba(0,0,0,0) !important';
  }
  else if (isFocused) {
    return 'var(--color-blue)' ;
  } else {
    return 'rgba(43, 56, 71, 0.5)';
  }
};

const _getHeight = (classNamePrefix) => {
  if (classNamePrefix === 'base-select-template' || classNamePrefix === 'base-select-dark') {
    return 'auto';
  } else {
    return 30;
  }
};

const _getPadding = (classNamePrefix) => {
  if (classNamePrefix === 'base-select-template') {
    return '0';
  } else if( classNamePrefix === 'base-select-dark' ) {
    return '6px 0 6px 10px';
  } else {
    return '8px 0 0 10px';
  }
};
