import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AsyncPaginate } from 'react-select-async-paginate';
import selectStyle from '../select/selectStyle';
import './selectAsync.css';

export default class SelectComponent extends Component {
  static propTypes = {
    name: PropTypes.string,
    placeholder: PropTypes.string,
    classNamePrefix: PropTypes.string,
    defaultOptions: PropTypes.bool,
    isMulti: PropTypes.bool,
    loadOptions: PropTypes.func,
    cacheUniqs: PropTypes.any,
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    onChange: PropTypes.func,
    disabled: PropTypes.bool
  };

  state = {
    inputValue: ''
  };

  render() {
    const {
      name,
      keyParams,
      placeholder,
      options,
      isMulti = false,
      hideSelectedOptions = true,
      formatOptionLabel,
      loadOptions,
      isSearchable = true,
      debounceTimeout = 300,
      cacheUniq,
      value,
      onChange,
      classNamePrefix = 'async-select',
      disabled,
      cacheUniqs,
      defaultOptions = false,
      //Manual open
      menuIsOpen,
      onMenuOpen,
      onMenuClose,
      noOptionsMessage,
      isOptionDisabled
    } = this.props;

    const manualOpenProps = onMenuOpen
      ? { menuIsOpen, onMenuOpen, onMenuClose, loadOptionsOnMenuOpen: true }
      : {};

    return (
      <AsyncPaginate
        {...manualOpenProps}
        defaultOptions={defaultOptions}
        noOptionsMessage={noOptionsMessage}
        cacheUniqs={[cacheUniqs]}
        loadOptions={loadOptions}
        debounceTimeout={debounceTimeout}
        options={options}
        cacheUniq={cacheUniq ? cacheUniq : options}
        value={value}
        name={name}
        key={keyParams} //for clear options
        isSearchable={isSearchable}
        formatOptionLabel={formatOptionLabel}
        hideSelectedOptions={hideSelectedOptions}
        isMulti={isMulti}
        closeMenuOnSelect={!isMulti}
        onChange={onChange ? e => onChange(e) : null}
        placeholder={placeholder}
        className="async-select__container"
        classNamePrefix={classNamePrefix}
        styles={{
          ...selectStyle,
          input: () => ({
            opacity: 1,
            position: 'absolute',
            top: '50%',
            left: 19,
            transform: 'translateY(-50%)'
          }),
          ...(() => {
            if (
              typeof formatOptionLabel === 'function' &&
              name !== 'sourceItems' &&
              name !== 'asyncSocialPages' &&
              name !== 'asyncFilterSelect'
            ) {
              const thumbIsNull = value.thumb === null;

              return {
                option: () => ({ cursor: 'pointer', height: 'auto', marginBottom: 8 }),
                input: () => ({ left: 54 }),
                singleValue: () => ({ color: thumbIsNull ? 'rgba(43, 56, 71, 0.5)' : '#2B3847' })
              };
            }
            if (name === 'asyncSocialPages' || name === 'asyncFilterSelect') {
              return {
                input: () => ({
                  opacity: 1,
                  position: 'static',
                  top: 'auto',
                  left: 'auto',
                  transform: 'none'
                })
              };
            }
            return {};
          })()
        }}
        isDisabled={disabled ? disabled : false}
        blurInputOnSelect={false}
        inputValue={this.state.inputValue}
        onInputChange={(query, { action }) => {
          if (action === 'input-change') this.setState({ inputValue: query });
        }}
        isOptionDisabled={isOptionDisabled ? isOptionDisabled : option => option.disabled}
      />
    );
  }
}
