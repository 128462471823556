// Packages
import React, { memo, lazy, Suspense, useMemo } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// Uitls
import withAuth from 'util/withAuth';
// Components
import ModalWrapper from 'components/modals';
import Loader from 'components/common/loader/loader';
import TourProvider from 'components/blocks/TourProvider/TourProvider';
import { UpgradePlanPopoverGlobal } from 'components/blocks/upgradePlanPopoverGlobal/upgradePlanPopoverGlobal';
import { UpgradePlanModal } from 'components/blocks/upgradePlanModal/upgradePlanModal';
import { FreePlanExtinctionModal } from 'components/blocks/freePlanExtinctionModal/freePlanExtinctionModal';
import { PromoPlanModal } from 'components/blocks/promoPlanModal/promoPlanModal';
import { UpgradePlanSelectStoreModal } from 'components/blocks/upgradePlanSelectStoreModal/upgradePlanSelectStoreModal';
import { CloudSelectModal } from 'components/blocks/cloudSelectModal/cloudSelectModal';
// Hooks
import { useAppModalsCronJob } from 'hooks';

const Publication = lazy(() => import('pages/publication/publication'));
const Login = lazy(() => import('pages/login/login'));
const Profile = lazy(() => import('pages/profile/profile'));
const Signup = lazy(() => import('pages/signup/signup'));
const Settings = lazy(() => import('pages/settings/settings'));
const Autopilot = lazy(() => import('pages/autopilot/autopilot'));
const CloudContributorApp = lazy(() => import('pages/cloudContributorApp/cloudContributorApp'));
const Home = lazy(() => import('components/landing/pages/Home/Home'));
const Templates = lazy(() => import('pages/templates/templates'));
const Billing = lazy(() => import('pages/billing/billing'));
const Users = lazy(() => import('pages/users/users'));
const Demos = lazy(() => import('pages/demos/demos'));
const Demo = lazy(() => import('components/landing/pages/Demo/Demo'));
const About = lazy(() => import('components/landing/pages/About/About'));
const PrivacyPolicy = lazy(() => import('components/landing/pages/PrivacyPolicy/PrivacyPolicy'));
const TermOfUse = lazy(() => import('components/landing/pages/TermOfUse/TermOfUse'));
const Page403 = lazy(() => import('components/landing/pages/Page403/Page403'));
const Page404 = lazy(() => import('components/landing/pages/Page404/Page404'));
const Page503 = lazy(() => import('components/landing/pages/Page503/Page503'));
const Pricing = lazy(() => import('components/landing/pages/Pricing/Pricing'));
const ContentPage = lazy(() => import('pages/content/content'));
const SpyFeed = lazy(() => import('pages/spyFeed/spyFeed'));
const MyCompetitors = lazy(() => import('pages/myCompetitors/myCompetitors'));
const Upgrade = lazy(() => import('pages/upgrade/upgrade'));
const AllIn = lazy(() => import('pages/allIn/allIn'));
// const Hire = lazy(() => import('pages/hire/hire'));
const CustomPlans = lazy(() => import('components/landing/pages/customPlans/customPlans'));
// const Partners = lazy(() => import('components/landing/pages/Partners/Partners'));
const BecomeAPartner = lazy(() => import('components/landing/pages/BecomeAPartner/BecomeAPartner'));
const Integrations = lazy(() => import('pages/integrations/integrations'));
const ProposeAFeature = lazy(() => import('pages/proposeAFeature/proposeAFeature'));
const Notifications = lazy(() => import('pages/notifications/notifications'));
const AclUsers = lazy(() => import('pages/acl/users/aclUsers'));
const AclCompanies = lazy(() => import('pages/acl/companies/aclCompanies'));

export const RouterConfig = memo(({ auth, loggedIn, location }) => {
  const {
    isUser,
    isManager,
    isAdmin,
    isCompanyManager,
    isCompanyOwner,
    isCompanyAdmin,
    isCompanyCloudContributor
  } = auth || {};

  useAppModalsCronJob();

  const Routes = useMemo(() => {
    const page403 = <Page403 />
    const page404 = <Page404 />

    return (
      <Suspense
        fallback={
          <Loader img={{ width: 100, height: 100 }} wrapper={{ isAllScreenHeight: true }} />
        }
      >
        <Switch>
          <Route
            path="/publication"
            component={() => (loggedIn ? ((isManager || isCompanyOwner || isCompanyAdmin || isCompanyManager) ? <Publication /> : page403) :
              <Redirect to="/login" />)}
          />
          <Route
            exact
            path="/"
            component={() => (loggedIn ? <Redirect to="/app" /> : <Redirect to="/home" />)}
          />
          <Route path="/home" component={() => <Home />} />
          <Route path="/demo" component={() => <Demo />} />
          <Route path="/about" component={() => <About />} />
          <Route path="/privacy-policy" component={() => <PrivacyPolicy />} />
          <Route path="/term-of-use" component={() => <TermOfUse />} />
          <Route path="/503" component={() => <Page503 />} />
          <Route path="/pricing" component={() => <Pricing />} />
          <Route path="/custom-plans" component={() => <CustomPlans />} />
          {/* <Route path="/partners" component={() => <Partners />} /> */}
          <Route path="/become-a-partner" component={() => <BecomeAPartner />} />

          <Route
            path="/app"
            render={() => {
              if (!loggedIn) return <Redirect to="/login" />;
              if (isAdmin) return <Redirect to="/users" />;
              if (isCompanyCloudContributor) return <Redirect to="/content/my-uploads/images" />;

              return <Redirect to="/publication" />;
            }}
          />
          <Route path="/login" component={() => <Login />} />
          <Route path="/signup" component={() => <Signup loggedIn={loggedIn} />} />
          <Route
            path="/profile"
            component={withAuth(() => (
              <Profile />
            ))}
          />

          <Route
            path="/billing"
            component={withAuth(() => (
              (isManager || isCompanyOwner || isCompanyAdmin) ? <Billing /> : page403
            ))}
          />
          <Route
            path="/autopilot"
            component={withAuth(() => (
              (isManager || isCompanyOwner || isCompanyAdmin || isCompanyManager) ? <Autopilot /> : page403
            ))}
          />
          <Route
            path="/contributor-app"
            component={withAuth(() => (
              (isUser || isManager) ? <CloudContributorApp /> : page403
            ))}
          />
          <Route
            path="/templates"
            component={withAuth(() => (
              (isManager || isCompanyOwner || isCompanyAdmin || isCompanyManager) ? <Templates /> : page403
            ))}
          />
          )}
          <Route
            path="/users"
            component={withAuth(() => (
              isAdmin ? <Users /> : page403
            ))}
          />
          <Route
            path="/demos"
            component={withAuth(() => (
              isAdmin ? <Demos /> : page403
            ))}
          />
          <Route
            path="/settings"
            component={withAuth(() => (
              <Settings />
            ))}
          />
          <Route
            path="/spy-feed"
            component={withAuth(() => (
              (isManager || isCompanyOwner || isCompanyAdmin || isCompanyManager) ? <SpyFeed /> : page403
            ))}
          />
          <Route
            path="/my-competitors"
            component={withAuth(() => (
              (isManager || isCompanyOwner || isCompanyAdmin || isCompanyManager) ? <MyCompetitors /> : page403
            ))}
          />
          <Route
            path="/upgrade"
            component={withAuth(() => (
              (isManager || isCompanyOwner || isCompanyAdmin) ? <Upgrade /> : page403
            ))}
          />
          <Route
            path="/all-in"
            component={withAuth(() => (
              (isManager || isCompanyOwner || isCompanyAdmin) ? <AllIn /> : page403
            ))}
          />
          {/* <Route
            path="/hire"
            component={withAuth(() => (
              (isManager || isCompanyOwner || isCompanyAdmin) ? <Hire /> : page403
            ))}
          /> */}
          <Route
            path="/integrations"
            exact={false}
            component={() => (!loggedIn || isManager || isCompanyOwner || isCompanyAdmin) ? <Integrations /> : page403}
          />
          <Route path="/propose-a-feature" exact={false} component={() => <ProposeAFeature />} />
          <Route
            path="/notifications"
            exact={false}
            component={withAuth(() => (
              (isManager || isCompanyOwner || isCompanyAdmin || isCompanyManager) ? <Notifications /> : page403
            ))}
          />
          <Route
            path="/content"
            exact={false}
            component={withAuth(() => (
              (isManager || isCompanyOwner || isCompanyAdmin|| isCompanyManager || isCompanyCloudContributor) ? <ContentPage auth={auth} /> : page403
            ))}
          />
          <Route
            path="/acl/users"
            component={withAuth(() => (
              (isManager || isUser) ? <AclUsers auth={auth} /> : page403
            ))}
          />
          <Route
            path="/acl/companies"
            component={withAuth(() => (
              (isManager || isUser) ? <AclCompanies /> : page403
            ))}
          />
          <Route
            render={routeProps => {
              if (!loggedIn || routeProps.location.pathname !== '/publication') {
                return page404;
              }
              return page403;
            }}
          />
          <Route path="*" element={page404} />
        </Switch>
      </Suspense>
    );
  }, [isAdmin]);

  return (
    <main>
      {Routes}
      <ModalWrapper />
      {loggedIn && (
        <>
          <ToastContainer />
          <TourProvider location={location} />
          <UpgradePlanPopoverGlobal />
          <UpgradePlanModal />
          <FreePlanExtinctionModal />
          <PromoPlanModal />
          <UpgradePlanSelectStoreModal />
          <CloudSelectModal />
        </>
      )}
    </main>
  );
});

export default RouterConfig;
